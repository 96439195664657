import * as React from "react";
import { styled, alpha, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SideBar from "./SideBar";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Avatar, Badge, MenuItem } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Link, useNavigate } from "react-router-dom";
import { COUNT_UNREAD_NOTIFICATIONS } from "@constants/api";
import axios from "@MyAxios";
import logo from "@images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { resetPosts, setClickedPostId } from "@redux/prevPostsSlice";
import CustomSelect from "./CustomSelect";
import { FilterList } from "@mui/icons-material";
import HomepageFilter from "./HomepageFilter";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#CACACA",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  border: "1px solid #CACACA",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  [theme.breakpoints.up("md")]: {
    width: "260px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingLeft: "10px",
  },
  cursor: "pointer",
}));

export default function Header({
  filters = false,
  handleGroupChange,
  handleCommunityChange
}) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userRecord } = useSelector((state) => state.user);

  const [open, setOpen] = React.useState(false);
  const [location, setLocation] = React.useState(window.location.pathname);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = React.useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogoClick = () => {
    if(window.location.pathname !== '/') {
      dispatch(resetPosts());
      dispatch(setClickedPostId("top-page"));
    }
  }

  React.useEffect(() => {
    if(userRecord?.joinedCommunities?.length === 1)
      handleCommunityChange?.(userRecord.joinedCommunities[0].id);

  }, [userRecord]);

  React.useEffect(() => {
    axios
      .get(COUNT_UNREAD_NOTIFICATIONS)
      .then((res) => {
        setUnreadNotificationsCount(res.data);
      })
      .catch(() => {});
  }, []);

  return (
    <div> 
      <div className={clsx("fixed z-50 w-full bg-white md:w-1/2", filters ? 'h-28' : 'h-14')}>
        <Toolbar className="flex items-center justify-between">
          <div className="flex items-center">
            <Link
              to="/"
              onClick={handleLogoClick}
            >
              <img src={logo} alt="Logo" className="mr-3 w-28" />
            </Link>
            <Link
              to={"/search"}
              state={{ prevLocation: location }}
              className=" cursor-pointer"
            >
              <Search>
                <StyledInputBase
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Link>
            <IconButton onClick={() => navigate("/notifications")}>
              {unreadNotificationsCount > 0 ? (
                <Badge
                  color="secondary"
                  badgeContent={unreadNotificationsCount}
                >
                  <NotificationsNoneOutlinedIcon
                    sx={{ color: "#b4b4b4", width: "40px", height: "30px" }}
                  />
                </Badge>
              ) : (
                <NotificationsNoneOutlinedIcon
                  sx={{ color: "#b4b4b4", width: "40px", height: "30px" }}
                />
              )}
            </IconButton>
          </div>

          <IconButton
            onClick={handleOpen}
            edge="start"
            aria-label="open drawer"
            sx={{ color: theme.palette.primary.main }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </Toolbar>

        {window.location.pathname === '/' && (
          <div className="flex justify-end px-4">
            <HomepageFilter
              handleGroupChange={handleGroupChange}
              handleCommunityChange={handleCommunityChange}
            />
          </div>
        )}

        <SideBar open={open} handleClose={handleClose} />
      </div>
    </div>
  );
}
