import React from "react";
import { Checkbox } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { addToConnections } from "../../redux/postSlice";

import MyAvatar from "@components/admin/MyAvatar";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function SelectConn({ users }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { connections } = useSelector((state) => state.post);

  const isSelected = (id) => {
    return connections.includes(id);
  };

  return (
    <div>
      <div className=" flex flex-col mt-2 mx-6 gap-2">
        {users.map((user, key) => (
          <div key={key} className="flex justify-between">
            <MyAvatar
              avatarSx={{ width: 30, height: 30, fontSize: 16 }}
              textProps={{ className: "font-bold", style: {fontSize: 14} }}
              subtextProps={{ className: 'text-xs' }}
              name={user?.userName}
              subtext={user?.firstName + " " + user?.lastName}
              src={user?.media?.avatarUrl}
            />
            <label key={key} className={clsx(classes.textGrey)}>
              <Checkbox
                className="py-1"
                sx={{ color: "green" }}
                checked={isSelected(user.id)}
                onChange={(e) => dispatch(addToConnections(user.id))}
              />
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectConn;
