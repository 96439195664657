import React, { useEffect, useMemo, useState } from "react";
import Title from "../../components/Title";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../MyAxios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADD_COMMUNITY_CONNECTION,
  CREATE_POST,
  ROOT,
} from "../../constants/api";
import Layout from "../../Layout";
import { resetPost } from "../../redux/postSlice";
import useLoggedIn from "../../hooks/useLoggedIn";

import { addToPosts } from "@redux/prevPostsSlice";
import { TextField, Typography } from "@mui/material";
import clsx from "clsx";
import { useTheme } from "@emotion/react";
import Loader from "@components/Loader";
import { parseAlphabets } from "@helpers";

function Tag({ name, onClick, isSelected }) {
  return (
    <div className="flex">
      <div
        onClick={onClick}
        style={{
          backgroundColor: isSelected
            ? "rgba(81, 205, 160, 0.25)"
            : "rgb(98, 98, 98, 0.1)",
        }}
        className="px-2 py-2 rounded-md cursor-pointer"
      >
        <Typography className="select-none">{name}</Typography>
      </div>
    </div>
  );
}
function SelectTags() {
  useLoggedIn();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const theme = useTheme();
  const post = useSelector((state) => state.post);
  const [search, setSearch] = useState("");
  const tagKeys = useMemo(() => Object.keys(tags), [tags]);
  const { tags: prevTags, selectedPostCommunities, isGroupOnlyPost, selectedGroups } = useSelector((state) => state.post);

  const fetchData = () => {
    setLoading(true);
    axios
      .get("tags", { params: { communityIds: selectedPostCommunities, search } })
      .then((res) => {
        setTags(res.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setSelectedTags(prevTags.map((tag) => tag.id));
  }, [id, setSelectedTags, prevTags]);

  useEffect(() => {
    if(!isGroupOnlyPost && selectedPostCommunities?.length === 0)
      navigate(-1);
    else
      fetchData();
  }, []);

  const onTagClick = (tagId) => {
    if(selectedTags.length >= 5 && !selectedTags.includes(tagId))
      return toast.error('You can select 5 tags at most');

    setSelectedTags((prev) => {
      if (prev.includes(tagId)) {
        return prev.filter((t) => t !== tagId);
      }
      return [...prev, tagId];
    });
  };

  const onSearchClick = () => {
    fetchData();
  };

  useEffect(() => {
    return () => {
      if (id) {
        dispatch(resetPost());
      }
    };
  }, [dispatch, id]);

  const handlePost = async () => {
    // if (selectedTags.length === 0) {
    //   return toast.error("Please select at least one tag");
    // }

    if (id) {
      try {
        const res = await axios.post(`/post/edit/${id}`, {
          title: post.title,
          description: post.description,
          mediaId: post.media,
          connections: Array.from(post.connections),
          communities: Array.from(post.communities),
          groups: Array.from(post.selectedGroups),
          tags: selectedTags,
          ageRestricted: post.ageRestricted,
        });
        dispatch(resetPost());
        dispatch(addToPosts(res.data));
        navigate(ROOT);
      } catch (error) {}
    } else {
      try {
        await axios
          .post(CREATE_POST, {
            title: post.title,
            description: post.description,
            mediaId: post.media,
            connections: Array.from(post.connections),
            communities: Array.from(post.communities),
            groups: Array.from(post.selectedGroups),
            selectedPostCommunities: post.selectedPostCommunities,
            tags: selectedTags,
            ageRestricted: post.ageRestricted,
          })
          .then((res) => {
            toast.success("Post created successfully");
            dispatch(resetPost());
            dispatch(addToPosts(res.data));
            navigate(ROOT, { replace: true });
          });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if(tagKeys && tags && post && selectedTags) {
      let tagsList = tagKeys.map(key => tags[key]).flat();

      let postWords = post.title?.split(' ')?.filter(word => word.length > 3);

      if(post.description)
        postWords = postWords.concat(post.description.split(' ')?.filter(word => word?.length > 3));

      let autoSelectedCats = {};
      postWords?.forEach(word => {
        let wordSmallcase = parseAlphabets(word?.toLowerCase());

        if(!wordSmallcase)
          return;

        tagsList.forEach(tag => {
          let tagSmallcase = tag.subcategory?.toLowerCase();

          if(!tagSmallcase)
            return;

          if(
            !autoSelectedCats[tag.id]
            && (tagSmallcase?.includes(wordSmallcase) || wordSmallcase?.includes(tagSmallcase))
          )
            autoSelectedCats[tag.id] = tag;
        })
      });

      setSelectedTags(Object.keys(autoSelectedCats));
    }
  }, [post, tags, tagKeys]);

  if (loading)
    return <Loader />;

  return (
    <Layout>
      <Title back={ADD_COMMUNITY_CONNECTION}>Select Topics</Title>
      <div className=" w-11/12 mx-auto">
        <TextField
          sx={{ "& fieldset": { border: "none" } }}
          className="mt-4 mb-6"
          type="text"
          size="small"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={(e) => e.key === "Enter" && onSearchClick()}
          placeholder="Search Topics"
          InputProps={{
            disableUnderline: true,
            sx: {
              borderRadius: 2,
              padding: 0.2,
              backgroundColor: "rgb(98, 98, 98, 0.1)",
              border: "none",
            },
          }}
        />
        <div className="px-4 pt-2">
          {/* {tagKeys.map((key) => (
            <div className="mb-2">
              <Typography
                sx={{ fontSize: "18px" }}
                className="font-semibold pb-2"
              >
                {key}
              </Typography>
              <div className="flex gap-2 flex-wrap">
                {tags[key].map((tag) => (
                  <Tag
                    onClick={() => onTagClick(tag.id)}
                    name={tag.subcategory}
                    isSelected={selectedTags.includes(tag.id)}
                  />
                ))}
              </div>
            </div>
          ))} */}
          <div className="flex gap-2 flex-wrap">
            {tagKeys?.length === 0 && (
              <Typography>
                No tags for selected communities
              </Typography>
            )}
            {tagKeys.map((key) => (
              tags[key].map((tag) => (
                <Tag
                  onClick={() => onTagClick(tag.id)}
                  name={tag.subcategory}
                  isSelected={selectedTags.includes(tag.id)}
                />
              ))
            ))}
          </div>

        </div>
        <div className=" flex items-end mt-5 justify-between overflow-hidden h-14 sticky bottom-0 bg-white py-2">
          <button
            style={{ backgroundColor: theme.palette.primary.main }}
            onClick={() => navigate(-1)}
            className={clsx("rounded-3xl p-2 px-6 text-white")}
          >
            Back
          </button>
          <button
            style={{ backgroundColor: theme.palette.primary.main }}
            onClick={handlePost}
            className={clsx("rounded-3xl p-2 px-6 text-white")}
          >
            {id ? "Update" : "Share"}
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default SelectTags;
