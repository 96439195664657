import React, { useEffect, useState } from 'react'
import Loader from '@components/Loader';
import Post from '@components/Post';
import axios from '@MyAxios';
import { useDispatch, useSelector } from 'react-redux';
import { refreshUserRecord } from '@helpers';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import ShowMore from '@components/ShowMore';
import { setProfilePostPage, setProfilePosts, setProfilePostsTotalPages, setProfileUser } from '@redux/postSlice';

function OtherUserMutualPosts({ user }) {
  const dispatch = useDispatch();

  const { userRecord } = useSelector((state) => state.user);
  const { clickedPostId } = useSelector((state) => state.prevPosts);
  const {
    profileUser,
    profilePosts,
    profilePostPage: page,
    profilePostsTotalPages: totalPages
  } = useSelector((state) => state.post);

  const [loading, setLoading] = useState(true);
  const [subsequentLoading, setSubsequentLoading] = useState(false);

  const fetchMutualPosts = async (newPage = page) => {
    try {
      setSubsequentLoading(true);
      await axios.get(`/posts/mutual/${user.id}`, {
        params: {
          page: newPage,
          limit: 15,
        }
      }).then((res) => {
        if(newPage == 1)
          dispatch(setProfilePosts(res.data.posts));
        else
          dispatch(setProfilePosts([ ...(profilePosts ?? []), ...res.data.posts ]));

        dispatch(setProfilePostPage(newPage));
        dispatch(setProfilePostsTotalPages(res.data.totalPages));

        setLoading(false);
        setSubsequentLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleIncrementPage = () => {
    fetchMutualPosts(page + 1);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      let postElement;
      if (clickedPostId) {
        postElement = document.getElementById(clickedPostId);
      }
      if (postElement) {
        postElement.scrollIntoView({ behavior: "smooth" });
        clearInterval(interval);
      }
    }, 100);
  }, [clickedPostId]);


  useEffect(() => {
    if(user?.id !== profileUser?.id) {
      dispatch(setProfilePostPage(1));
      fetchMutualPosts(1)
        .then(() => dispatch(setProfileUser(user)));
    }
  }, [user])

  const newProfilePostsLoading = loading && (!profileUser?.id || user?.id !== profileUser?.id);

  return (
    <div>
      {newProfilePostsLoading ? (
        <Loader />
      ) : (
        <div className={subsequentLoading ? 'opacity-50' : ''}>
          {(profilePosts?.length === 0) && (
            <Typography className='px-5 pt-5 border-t-2'>
              No mutual posts...
            </Typography>
          )}
          {profilePosts?.map((post, index) => (
            <div
              key={post.id}
              id={
                post.groups?.[0]
                ? `group-${post.groups?.[0]?.id}-${post?.id}`
                : `post-${post.id}`}
              sx={{
                borderColor: (theme) => theme.palette.primary.main,
                scrollMarginTop: 110
              }}
              className={clsx("border-b-2", index === 0 ? 'border-t-2' : '')}
            >
              <Post
                noHeader
                comment
                post={post}
                group={post.groups[0]}
                savedItems={userRecord.savedItems}
                setGetSaved={() => refreshUserRecord(dispatch)}
              />
            </div>
          ))}
          {page < totalPages && (
            <div
              onClick={handleIncrementPage}
              className="cursor-pointer my-2 text-center mx-4"
            >
              <ShowMore>Show More</ShowMore>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default OtherUserMutualPosts;