import React, { useEffect, useState } from "react";
import { Checkbox, Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Title from "../../components/Title";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CREATE_POST,
  GET_COMMUNITIES,
  GET_CONNECTIONS,
  GET_GROUPS_BY_MEMBER,
} from "../../constants/api";
import Layout from "../../Layout";
import {
  addAllToConnections,
  addAllToGroups,
  addAllToPostCommunities,
  addToGroups,
  addToPostCommunities,
  clearConnections,
  clearGroups,
  clearPostCommunities,
  resetPost,
} from "../../redux/postSlice";
import useLoggedIn from "../../hooks/useLoggedIn";
import SelectGroup from "./SelectGroup";
import SelectConn from "./SelectConn";
import Loader from "@components/Loader";
import axios from "@MyAxios";
import SelectPostCom from "./SelectPostCom";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",

    '&:disabled': {
      opacity: 0.5
    }
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function AddComCon() {
  useLoggedIn();
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  // const { connections, selectedGroups } = useSelector((state) => state.post);
  const [showCommunities, setShowCommunities] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [showConnections, setShowConnections] = useState(false);
  const post = useSelector((state) => state.post);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [communities, setCommunities] = useState([]);
  const { title, connections, selectedGroups, selectedPostCommunities } = useSelector((state) => state.post);
  const { userRecord } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [communitySelectionLoading, setCommunitySelectionLoading] = useState(false);
  const [singleComGroupChoice, setSingleComGroupChoice] = useState('');
  const [singleCommunityDataFetched, setSingleCommunityDataFetched] = useState(false);

  useEffect(() => {
    if(userRecord?.id) {
      setLoading(true);
      axios.get(GET_COMMUNITIES)
        .then(response => {
          let unbannedCommunities = response.data.filter(com => {
            return !userRecord.communitiesBanned.some(c => c.id === com.id)
              && com.disabledAt === 0;
          });

          if(unbannedCommunities.length === 1) {
            setSingleComGroupChoice('community');
            dispatch(addToPostCommunities(unbannedCommunities[0].id))
            dispatch(clearGroups());
          }
          setCommunities(unbannedCommunities);
          setLoading(false);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userRecord]);

  useEffect(() => {
    if(!singleCommunityDataFetched) {
      dispatch(clearGroups());
      dispatch(clearConnections());

      if(selectedPostCommunities?.length) {
        setCommunitySelectionLoading(true);
        Promise.all([
          axios.get('/community-joined-groups', { params: { communityIds: selectedPostCommunities } }),
          axios.get('/community-joined-connections', { params: { communityIds: selectedPostCommunities } })
        ])
          .then(responses => {
            let tempGroups = responses[0].data;
            let tempUsers = responses[1].data;

            setGroups(tempGroups);
            setUsers(tempUsers);

            if(singleComGroupChoice === 'community' && communities.length === 1)
              setSingleCommunityDataFetched(true);
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            setCommunitySelectionLoading(false);
          });

        } else {
          setGroups([]);
          setUsers([]);
        }
    }
  }, [selectedPostCommunities]);

  const handlePost = async () => {
    if (id) {
      navigate(`/select-tags/${id}`);
    } else {
      navigate("/select-tags");
    }
  };

  const selectAllConnections = (e) => {
    e.stopPropagation();
    setShowConnections(true);
    if (connections.length !== users.length) {
      dispatch(addAllToConnections(users.map((user) => user.id)));
    } else {
      dispatch(clearConnections([]));
    }
  };

  const handleSingleComGroupChoice = (choice) => {
    setSingleComGroupChoice(choice);
    
    if(choice === 'community' && communities.length === 1) {
      dispatch(addToPostCommunities(communities[0].id))
      dispatch(clearGroups());

    } else if(choice === 'group') {
      if(selectedGroups?.[0] == groups?.[0]?.id)
        dispatch(clearGroups());

      else if(groups?.length === 1)
        dispatch(addToGroups(groups[0].id));
    }
  }

  useEffect(() => {
    if(!title)
      navigate(-1);
  }, [])

  const nextDisabled = !Boolean(selectedPostCommunities?.length) && !Boolean(selectedGroups?.length) && !Boolean(connections?.length);

  if (loading)
    return <Loader />;

  return (
    <Layout>
      <Title back={CREATE_POST}>Post Stream</Title>

      {(!loading && communities.length === 1) ? (
        <div className="flex justify-between items-center mx-12 my-5">
          <Typography className={classes.textGrey}>
            <strong>Post to Community:</strong>
            &nbsp;
            { communities?.[0]?.name }
          </Typography>

          <Checkbox
            sx={{ color: "green" }}
            name="singleComGroupChoice"
            checked={(singleComGroupChoice === 'community') && (selectedPostCommunities?.length === 1)}
            onClick={(e) => handleSingleComGroupChoice('community')}
          />
        </div>
      ) : (
        <div className="m-5 mt-10">
          <div
            onClick={() => setShowCommunities(!showCommunities)}
            className={clsx(
              classes.bgGrey,
              "mx-5 flex cursor-pointer justify-between items-center rounded-lg p-3"
            )}
          >
            {showCommunities ? (
              <ArrowForwardIcon fontSize="10" className="rotate-90 mr-3" />
            ) : (
              <ArrowForwardIcon fontSize="10" className="mr-3" />
            )}

            <Typography className={clsx(classes.textGrey)}>
              Select Communities
            </Typography>
            <div className="ml-auto"></div>
          </div>
          {showCommunities && <SelectPostCom communities={communities} />}
        </div>
      )}

      {Boolean(selectedPostCommunities?.length) && (
        (!loading && groups.length === 1) ? (
          <div className="flex justify-between items-center mx-12 mb-3">
            <Typography className={classes.textGrey}>
              <strong>Post to Group:</strong>
              &nbsp;
              { groups?.[0]?.name }
            </Typography>

            <Checkbox
              sx={{ color: "green" }}
              name="singleComGroupChoice"
              checked={(singleComGroupChoice === 'group') && (selectedGroups?.length === 1)}
              onClick={(e) => handleSingleComGroupChoice('group')}
            />
          </div>
        ) : (
          <>
            <div className="m-5">
              <div
                onClick={() => setShowGroups(!showGroups)}
                className={clsx(
                  classes.bgGrey,
                  "mx-5 flex cursor-pointer justify-between items-center rounded-lg p-3"
                )}
              >
                {showGroups ? (
                  <ArrowForwardIcon fontSize="10" className="rotate-90 mr-3" />
                ) : (
                  <ArrowForwardIcon fontSize="10" className="mr-3" />
                )}
  
                <Typography className={clsx(classes.textGrey)}>
                  {communitySelectionLoading ? 'Loading...' : 'Select Groups'}
                </Typography>
                <div className="ml-auto"></div>
              </div>
              {showGroups && <SelectGroup groups={groups} />}
            </div>
          </>
        )
      )}

      {Boolean(selectedPostCommunities?.length) && (
        <div className="m-5">
          <div
            onClick={() => setShowConnections(!showConnections)}
            className={clsx(
              classes.bgGrey,
              "mx-5 flex cursor-pointer items-center rounded-lg p-3"
            )}
          >
            {showConnections ? (
              <ArrowForwardIcon fontSize="10" className="rotate-90 mr-3" />
            ) : (
              <ArrowForwardIcon fontSize="10" className="mr-3" />
            )}

            <Typography className={clsx(classes.textGrey)}>
              {communitySelectionLoading ? 'Loading...' : 'Select Connections'}
            </Typography>
            <div className="ml-auto"></div>

            <Checkbox
              className="p-0 pe-px"
              sx={{ color: "green" }}
              checked={users.length && (connections.length === users.length)}
              indeterminate={Boolean(connections.length) && connections.length < users.length}
              onChange={(e) => {
                selectAllConnections(e);
              }}
            />
          </div>
          {showConnections && <SelectConn users={users} />} 
        </div>
      )}

      <div className=" flex items-end mt-16 mx-10 justify-between">
        <button
          onClick={() => navigate(-1)}
          className={clsx(classes.button, "rounded-3xl p-2 px-6")}
        >
          Back
        </button>
        <button
          onClick={handlePost}
          className={clsx(classes.button, "rounded-3xl p-2 px-6")}
          disabled={nextDisabled}
        >
          Next
        </button>
      </div>
    </Layout>
  );
}

export default AddComCon;
