import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    user: "",
    passwordResetUser: "",
    passwordResetOtp: "",
    userRecord: {},
    isLoggedIn: localStorage.token ? true : false,

    // homepage filters
    filterSelectedTags: localStorage.homepageTags ? JSON.parse(localStorage.homepageTags) : [],
    filterFromDate: null,
    filterToDate: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPasswordResetUser: (state, action) => {
      state.passwordResetUser = action.payload;
    },
    setPasswordResetOtp: (state, action) => {
      state.passwordResetOtp = action.payload;
    },
    setUserRecord: (state, action) => {
      state.userRecord = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setFilterSelectedTags: (state, action) => {
      state.filterSelectedTags = action.payload;
    },
    setFilterFromDate: (state, action) => {
      state.filterFromDate = action.payload;
    },
    setFilterToDate: (state, action) => {
      state.filterToDate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setPasswordResetUser,
  setPasswordResetOtp,
  setUserRecord,
  setIsLoggedIn,
  setFilterSelectedTags,
  setFilterFromDate,
  setFilterToDate,
} = UserSlice.actions;

export default UserSlice.reducer;
