import { useSelector } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, MenuList, MenuItem, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MyAvatar from "@components/admin/MyAvatar";
import clsx from "clsx";
import { useEffect, useState } from "react";
import axios from "@MyAxios";
import { toast } from "react-toastify";
import Loader from "@components/Loader";

const ShareWithConnectionDialog = ({ post, open, onClose, onSuccess }) => {
  const navigate = useNavigate();

  const userRecord = useSelector((state) => state.user.userRecord);

  const [loading, setLoading] = useState(false);
  const [selectedConnections, setSelectedConnections] = useState([]);

  const [connectionsLoading, setConnectionsLoading] = useState(false);
  const [connections, setConnections] = useState();

  const handleClose = () => {
    if(loading)
      return;

    setSelectedConnections([]);
    onClose();
  }

  const handleConnectionSelect = (connectionId) => {
    if(selectedConnections.includes(connectionId)) {
      setSelectedConnections(selectedConnections.filter((id) => id !== connectionId));
      return;
    }

    setSelectedConnections([...selectedConnections, connectionId]);
    // navigate(`/user/chat/${connectionId}`, { state: { preFill: content } });
  }

  const handleSend = () => {
    if(selectedConnections.length === 0)
      return toast.error('At least one connection must be selected');

    setLoading(true);
    axios.post("/share-with-connections", {
      postId: post.id,
      connectionIds: selectedConnections
    })
      .then(() => {
        toast.success(`Post shared successfully with ${selectedConnections.length} connections`);
        onSuccess?.();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }

  const fetchShareableConnections = () => {
    setConnectionsLoading(true);
    axios.get("/get-shareable-connections", { params: { postId: post.id } })
      .then(response => {
        setConnections(response.data);     
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setConnectionsLoading(false));
  }

  const getNoConnectionsNote = () => {
    if (post.groups?.length > 0)
      return 'No connections found in post group';

    if (post.communities?.length > 0)
      return 'No connections found in post community';

    return 'No connections found';
  }

  useEffect(() => {
    if(open && !connections?.length)
      fetchShareableConnections();
  }, [open])

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: 'rounded-xl'
      }}
    >
      <DialogTitle>Share with connections</DialogTitle>

      <DialogContent className="flex flex-col gap-4 max-h-[300px] overflow-y-auto">
        {connectionsLoading && (
          <Loader />
        )}
        {!connectionsLoading && (
          <>
            {connections?.length === 0 && (
              <Typography className="text-center">{getNoConnectionsNote()}</Typography>
            )}
    
            {connections?.map((connection) => {
              const alreadyConnected = post.connections?.some((conn) => conn.id === connection.id);

              return (
                <div key={connection.id} className={`flex justify-between ${alreadyConnected && 'opacity-50'}`}>
                  <MyAvatar
                    avatarSx={{ width: 30, height: 30, fontSize: 16 }}
                    textProps={{ className: "font-bold", style: {fontSize: 14} }}
                    subtextProps={{ className: 'text-xs' }}
                    name={connection?.userName}
                    subtext={connection?.firstName + " " + connection?.lastName}
                    src={connection?.media?.avatarUrl}
                  />
    
                  <label className='text-[#626262]'>
                    <Checkbox
                      disabled={alreadyConnected}
                      className="py-1"
                      sx={{ color: "green" }}
                      checked={selectedConnections.includes(connection.id) || alreadyConnected}
                      onChange={(e) => handleConnectionSelect(connection.id)}
                    />
                  </label>
                </div>
              )
            })}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleSend}
        >
          {loading ? "Sending..." : "Send"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareWithConnectionDialog;
