import { IconButton, Modal } from "@mui/material";
import Button from "./Button";

import { ModalBox } from "@components/admin/StyledComponents";
import MyAvatar from "../MyAvatar";
import { formatDate } from "@helpers";
import DetailRow from "../DetailRow";
import { useEffect, useState } from "react";
import CommentText from "@components/CommentText";
import ReportCount from "./ReportCount";
import { Close as CloseIcon } from "@mui/icons-material";

export default function MessageReportDetail({
  setMessageReport,
  messageReport,
  actionButtons,
}) {
  const [open, setOpen] = useState(false);
  const user = messageReport?.messageReported?.user;
  const reporter = messageReport?.reportedBy;
  const message = messageReport?.messageReported;
  useEffect(() => {
    if (messageReport) setOpen(true);
  }, [messageReport]);
  if (!messageReport) return <div></div>;

  const hanldeDialogClose = () => {
    setMessageReport(null);
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={hanldeDialogClose}
    >
      <ModalBox className="shadow-md overflow-auto">
        <div className="flex justify-between items-start">
          <div className="flex gap-6">
            <MyAvatar
              src={user?.media?.avatarUrl}
              name={user?.firstName + " " + user?.lastName}
              subtext={`@${user?.userName}`}
              textProps={{ className: "font-bold text-gray-500" }}
            />
            <ReportCount count={messageReport?.reportCount} />
          </div>

          <IconButton onClick={hanldeDialogClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <div className="flex">
            <table className="w-full text-left text-sm mt-5">
              <tbody>
                <DetailRow label="Email:" value={user?.emailAddress} />
                <DetailRow
                  label="Signin Option:"
                  value={user?.provider || "Email"}
                />
                <DetailRow
                  label="Registration Date:"
                  value={formatDate(user?.createdAt)}
                />
                <DetailRow
                  label="Reporter:"
                  value={reporter?.emailAddress}
                />
                <DetailRow
                  label="Reporter Username:"
                  value={reporter?.userName}
                />
                <DetailRow
                  label="Reported On:"
                  value={formatDate(messageReport?.createdAt)}
                />
                <DetailRow
                  showFull
                  label="Report Description:"
                  value={messageReport?.reason}
                />
              </tbody>
            </table>
            <div
              className="mt-12 ml-28
            "
            >
              <CommentText>{message?.message}</CommentText>
            </div>
          </div>

          <div className="flex gap-2 mt-4 justify-end">
            {actionButtons?.map((button, index) => (
              <Button variant="contained" key={index} {...button} />
            ))}
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}
