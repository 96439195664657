import { IconButton, Modal } from "@mui/material";
import Button from "./Button";

import { ModalBox } from "@components/admin/StyledComponents";
import MyAvatar from "../MyAvatar";
import { formatDate } from "@helpers";
import DetailRow from "../DetailRow";
import { useEffect, useState } from "react";
import ReportCount from "./ReportCount";
import { Close as CloseIcon } from "@mui/icons-material";

export default function UserReportDetail({
  setUserReport,
  userReport,
  actionButtons,
}) {
  const [open, setOpen] = useState(false);
  const user = userReport?.userReported;
  const reporter = userReport?.reportedBy;
  useEffect(() => {
    if (userReport) setOpen(true);
  }, [userReport]);
  if (!userReport) return <div></div>;

  const hanldeDialogClose = () => {
    setUserReport(null);
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={hanldeDialogClose}
    >
      <ModalBox className="shadow-md overflow-auto">
        <div className="flex justify-between items-start">
          <div className="flex gap-6">
            <MyAvatar
              src={user?.media?.avatarUrl}
              name={user?.firstName + " " + user?.lastName}
              subtext={`@${user?.userName}`}
              textProps={{ className: "font-bold text-gray-600" }}
            />
            <ReportCount count={userReport?.reportCount} />
          </div>

          <IconButton onClick={hanldeDialogClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <table className="w-full text-left text-sm mt-5">
            <tbody>
              <DetailRow label="Email:" value={user?.emailAddress} />
              <DetailRow label="Signin Option:" value={user?.provider || "Email"} />
              <DetailRow
                label="Registration Date:"
                value={formatDate(user?.createdAt)}
              />
              <DetailRow
                label="Reporter:"
                value={reporter?.emailAddress}
              />
              <DetailRow
                label="Reporter Username:"
                value={reporter?.userName}
              />
              <DetailRow
                label="Reported On:"
                value={formatDate(userReport?.createdAt)}
              />
              <DetailRow
                showFull
                label="Report Description:"
                value={userReport?.reason}
              />
            </tbody>
          </table>
          <div className="flex gap-2 mt-4 justify-end">
            {actionButtons?.map((button, index) => (
              <Button variant="contained" key={index} {...button} />
            ))}
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}
