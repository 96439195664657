export default {
  control: {
    backgroundColor: "#fff",
    fontWeight: "normal",
  },

  "&multiLine": {
    control: {
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
    },
    input: {
      padding: 10,
      borderWidth: 2,
      borderColor: "#887E7E",
      borderRadius: 4,
      overflowY: "auto",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      fontSize: 14,
      maxHeight: "150px",
      overflow: "auto",
    },
    item: {
      padding: "5px 15px",
      "&focused": {
        backgroundColor: "rgba(81, 205, 160,0.2)",
      },
    },
  },
};
