import clsx from "clsx";

export default function DetailRow({ label, value, showFull = false }) {
  return (
    <tr className={"border-b-2 border-gray-200"}>
      <td className="max-w-80 truncate px-6 py-4 font-bold text-gray-600">
        {label}
      </td>{" "}
      <td k className={clsx("max-w-80 px-6 py-4", !showFull ? 'truncate' : '')}>
        {value}
      </td>
    </tr>
  );
}
