import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "@mui/material";
import Post from "../../components/Post";
import axios from "../../MyAxios";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_POST,
  GET_LOGGED_USER,
  GET_POSTS,
  GET_SAVED_POSTS_ID,
} from "../../constants/api";
import Layout from "../../Layout";
import { Navigate, useNavigate } from "react-router-dom";
import { setUserRecord } from "../../redux/userSlice";
import useLoggedIn from "../../hooks/useLoggedIn";
import addPost2 from "../../assets/img/addPost2.svg";
import { useTheme } from "@emotion/react";
import {
  removeFromPosts,
  resetPosts,
  setClickedPostId,
  setPage,
  setPosts,
  setTotalPages,
} from "@redux/prevPostsSlice";
import ShowMore from "@components/ShowMore";
import { clearGroups, setIsGroupOnlyPost } from "@redux/postSlice";
import AdUnit from "@components/AdUnit";

function HomePage() {
  useLoggedIn();

  const firstRender = useRef(true);

  const [postsLoading, setPostsLoading] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState(localStorage.homepageGroups ? JSON.parse(localStorage.homepageGroups) : []);
  const [selectedCommunity, setSelectedCommunity] = useState(localStorage.homepageCommunity);
  const [savedItems, setSavedItems] = useState([]);
  const [getSaved, setGetSaved] = useState(0);
  const { posts, page, totalPages, clickedPostId } = useSelector(
    (state) => state.prevPosts
  );
  const post = useSelector((state) => state.post);
  const {
    userRecord,
    filterSelectedTags,
    filterFromDate,
    filterToDate,
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  // TODO: We can have a loop here if the content is too much and 300 milliseconds is not enough
  useEffect(() => {
    const interval = setInterval(() => {
      let postElement;
      if (clickedPostId) {
        postElement = document.getElementById(clickedPostId);
      }
      if (postElement) {
        clearInterval(interval);
        postElement.scrollIntoView({ behavior: "smooth" });
        dispatch(setClickedPostId(''));
      }
    }, 100);
  }, [clickedPostId]);

  const removePost = (id) => {
    dispatch(removeFromPosts(id));
  };
  useEffect(() => {
    const getSavedItems = async () => {
      try {
        await axios.get(GET_SAVED_POSTS_ID).then((res) => {
          setSavedItems(res.data);
        });
      } catch (error) {}
    };
    getSavedItems();
  }, [getSaved]);

  const GetPosts = async (page, callback) => {
    try {
      setPostsLoading(true);
      await axios.get(`${GET_POSTS}/${page}`, {
        params: {
          selectedCommunity,
          selectedGroups,
          selectedTags: filterSelectedTags,
          fromDate: filterFromDate,
          toDate: filterToDate,
          limit: 15
        }
      }).then((res) => {
        callback?.();
        dispatch(setPosts(res.data.posts));
        dispatch(setTotalPages(res.data.totalPages));
        setPostsLoading(false);
      });
    } catch (error) {
      setPostsLoading(false);
    }
  };

  const handleCommunityChange = (comId) => {
    setSelectedCommunity(comId);

    if(comId) {
      handleGroupChange([]);
      localStorage.setItem('homepageCommunity', comId);

    } else {
      localStorage.removeItem('homepageCommunity');
    }
  }

  const handleGroupChange = (groupIds) => {
    setSelectedGroups(groupIds);
    
    if(groupIds?.length) {
      // handleCommunityChange();
      localStorage.setItem('homepageGroups', JSON.stringify(groupIds));

    } else {
      localStorage.removeItem('homepageGroups');
    }
  }

  useEffect(() => {
    if (page === 1 && posts.length === 0) {
      GetPosts(page, () => firstRender.current = false);
    }
  }, [page]);

  useEffect(() => {
    // If homepage rendered by clicking back icon, we don't call any api but disable first render to enable community toggling
    if(firstRender.current && posts.length > 0) {
      firstRender.current = false;
      return;
    }

    if(!firstRender.current) {
      let currPage = clickedPostId ? page : 1;

      dispatch(setPage(currPage));
      GetPosts(currPage, () => dispatch(resetPosts()));
      document.body.scrollIntoView();
    }
  }, [selectedCommunity, selectedGroups, filterSelectedTags, filterFromDate, filterToDate]);

  if(localStorage.loginRedirect) {
    let redirectPath = localStorage.loginRedirect;
    localStorage.removeItem('loginRedirect');
    return <Navigate to={redirectPath} />
  }

  const postingIsDisabled = !Boolean(userRecord?.joinedCommunities?.some(com => com.disabledAt === 0));

  return (
    <Layout
      filters
      handleGroupChange={(groupId) => handleGroupChange(groupId)}
      handleCommunityChange={(comId) => handleCommunityChange(comId)}
    >
      <div
        id="top-page"
        style={{
          opacity: postsLoading ? 0.5 : 1,
          minHeight: `calc(100vh - 224px)`
        }}
      >
        {posts?.map((item, key) => {
          let postGroups = item?.groups;

          // Only show posts with groups that user shares
          if(Boolean(postGroups?.length)) {
            postGroups = postGroups.filter(group =>
              userRecord?.groups?.some(userGroup => userGroup.id == group.id)
              || userRecord?.groupsAdmin?.some(userGroup => userGroup.id == group.id)
              || userRecord?.groupsModerated?.some(userGroup => userGroup.id == group.id)
            );
          }

          if (postGroups?.length > 0) {
            return (
              <div key={key}>
                {postGroups
                  .filter((g) => g?.deletedAt === 0 && g?.disabledAt === 0)
                  .map((group, index) => (
                    <div
                      id={`group-${group?.id}-${item?.id}`}
                      key={index}
                      style={{ borderColor: theme.palette.primary.main, scrollMarginTop: 110 }}
                      className=" border-b-2"
                    >
                      <Post
                        group={group}
                        post={item}
                        savedItems={savedItems}
                        setGetSaved={setGetSaved}
                        getSaved={getSaved}
                        comment={true}
                        removePost={() => removePost(item?.id)}
                      />
                    </div>
                  ))}
              </div>
            );
          } else {
            return (
              <div
                id={`post-${item.id}`}
                key={key}
                style={{ borderColor: theme.palette.primary.main, scrollMarginTop: 110 }}
                className="border-b-2"
              >
                <Post
                  post={item}
                  savedItems={savedItems}
                  setGetSaved={setGetSaved}
                  getSaved={getSaved}
                  comment={true}
                  removePost={() => removePost(item.id)}
                />
              </div>
            );
          }
        })}
        {page < totalPages && (
          <div
            onClick={() => {
              dispatch(setPage(page + 1));
              GetPosts(page + 1);
            }}
            className="cursor-pointer my-2 text-center mx-4"
          >
            <ShowMore>Show More</ShowMore>
          </div>
        )}
      </div>

      {!postingIsDisabled && (
        <div
          onClick={() => {
            if (post.isGroupOnlyPost) {
              dispatch(clearGroups());
              dispatch(setIsGroupOnlyPost(false));
            }
            navigate(CREATE_POST);
          }}
          className="sticky bottom-10 ml-auto mr-2"
        >
          <Avatar className="h-12 w-12 md:h-20 md:w-20" src={addPost2} />
        </div>
      )}
    </Layout>
  );
}

export default HomePage;
