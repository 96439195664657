import { Modal } from "@mui/material";
import Button from "./Button";

import { ModalBox } from "@components/admin/StyledComponents";
import MyAvatar from "@components/admin/MyAvatar";
import DetailRow from "@components/admin/DetailRow";
import { useEffect, useState } from "react";
import api from "@apiClient";
import { toast } from "react-toastify";

export default function ReportWarning({ report, setReport, user }) {
  const [open, setOpen] = useState(false);
  const [warning, setWarning] = useState("");
  const sendWarning = () => {
    api
      .sendWarning({ id: report.id, warning, emailAddress: user?.emailAddress })
      .then(() => {
        setReport(null);
        setOpen(false);
        toast.success("Warning Sent Successfully");
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (report) setOpen(true);
  }, [report]);
  if (!report) return <div></div>;
  return (
    <Modal
      open={open}
      onClose={() => {
        setReport(null);
        setOpen(false);
      }}
    >
      <ModalBox className=" shadow-md overflow-auto">
        <div className="flex gap-6">
          <MyAvatar
            src={user?.media?.avatarUrl}
            name={user?.firstName + " " + user?.lastName}
            subtext={`@${user?.userName}`}
            textProps={{ className: "font-bold text-gray-600" }}
          />
        </div>
        <div>
          <table className="w-full text-left text-sm mt-5">
            <tbody>
              <DetailRow label="Email:" value={user?.emailAddress} />
              <DetailRow showFull label="Report Description:" value={report?.reason} />
              <DetailRow label="Warning Message:" value={""} />
            </tbody>
          </table>

          <textarea
            className="border-gray-200 border-2 rounded-md mt-5 w-1/2 p-2"
            rows={6}
            onChange={(e) => setWarning(e.target.value)}
          />
          <div className="flex gap-2 mt-4 justify-end ">
            <Button variant="contained" color="warning" onClick={sendWarning}>
              Send
            </Button>
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}
