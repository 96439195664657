import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../components/Title";
import axios from "@MyAxios";
import { toast } from "react-toastify";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  BLOCK_USER,
  EDIT_PROFILE,
  GET_USER_CHAT,
  PROFILE_UPDATE,
  REMOVE_CONNECTION,
  REQUEST_CONNECTION,
  ROOT,
} from "../../constants/api";
import TabPane from "../../components/TabPane";
import camera from "../../assets/img/camera.svg";
import Layout from "../../Layout";
import useLoggedIn from "../../hooks/useLoggedIn";
import MyConnections from "@components/Connections";
import MyGroups from "@components/MyGroups";
import { MoreVert } from "@mui/icons-material";
import addConnection from "@images/addConnection.svg";
import remove from "@images/remove.svg";
import BlockIcon from "../../assets/img/block.svg";
import message from "@images/message.svg";
import GroupModal from "@components/GroupModal";
import MyCommunities from "@components/MyCommunities";
import { refreshUserRecord } from "@helpers";
import QRCode from 'qrcode'
import share from "../../assets/img/share.svg";
import QrModalImage from "@components/qr-modal-image/QrModalImage";
import OtherUserMutualPosts from "./OtherUserMutualPosts";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  mainContainer: {
    height: 'calc(100vh - 56px)'
  }
}));

function Profile() {
  useLoggedIn();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userRecord, isLoggedIn } = useSelector((state) => state.user);

  let { userName } = useParams();
 
  userName = userName || userRecord?.userName;

  const [file, setProfilePic] = useState();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const isOtherUser = userName !== userRecord.userName;
  const fileInput = useRef(null);

  const [openUserOptions, setOpenUserOptions] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [qrCodeDataUri, setQrCodeDataUri] = useState();

  const activeTab = Number(
    searchParams.get("activeTab") === "null"
      ? "0"
      : searchParams.get("activeTab")
  );

  const setActiveTab = useCallback(
    (activeTab) => {
      setSearchParams(() => {
        return { activeTab };
      });
    },
    [setSearchParams]
  );

  const handleQrCodeShare = async() => {
    const blob = await fetch(qrCodeDataUri).then(res => res.blob());
    alert(blob.type)
    const file = new File(
      [blob],
      `${userName}_serkolhs_qr.${blob.type.split('/')?.[1]}`,
      {
        type: blob.type
      }
    );

    navigator.share({
      title: `${userName}_serkolhs_qr`,
      files: [ file ],
    });
  }

  const tabs = useMemo(() => ["Communities", "Groups", "Connections"], []);

  const onTabChange = (index) => {
    if (index >= 0 && index < tabs.length) setActiveTab(index);
  };
  const userOptions = [
    {
      id: 2,
      name: "Message",
      img: message,
      action: () => {
        navigate(`${GET_USER_CHAT}/${user?.id}`);
      },
    },
    {
      id: 3,
      name: "Block",
      img: BlockIcon,
      action: () => {
        axios
          .post(BLOCK_USER, { id: user?.id })
          .then((res) => {
            toast.success("User blocked successfully");
            setOpenUserOptions(false);
          })
          .catch(() => {});
      },
    },
  ];

  if (!userRecord?.connections?.map((x) => x.id).includes(user?.id)) {
    userOptions.unshift({
      id: 1,
      name: "Connect",
      img: addConnection,
      action: () => {
        axios
          .post(REQUEST_CONNECTION, { forUser: user?.id })
          .then((res) => {
            toast.success("Connection request sent successfully");
            setOpenUserOptions(false);
          })
          .catch(() => {});
      },
    });
  } else {
    userOptions.unshift({
      id: 1,
      name: "Remove Connection",
      img: remove,
      action: () => {
        axios
          .post(REMOVE_CONNECTION, { id: user?.id })
          .then((res) => {
            toast.success("Connection removed successfully");
            setOpenUserOptions(false);
            refreshUserRecord(dispatch);
          })
          .catch(() => {});
      },
    });
  }

  useEffect(() => {
    localStorage.removeItem("formData");
  }, []);

  useEffect(() => {
    if(!isLoggedIn)
      localStorage.setItem('loginRedirect', `/${userName}`)
  }, [isLoggedIn, navigate, userName]);

  useEffect(() => {
    QRCode.toDataURL(`${process.env.REACT_APP_BASE_URL}/${userName}`, {
      errorCorrectionLevel: 'L'
    })
      .then(url => setQrCodeDataUri(url));

  }, [userName])

  const onChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3 * 1024 * 1024) {
        toast.error("File size should be less than 3MBs");
        return;
      }

      setProfilePic(e.target.files[0]);
    }
  };
  useEffect(() => {
    if (isOtherUser) {
      setUser();
      axios
        .get(`/user/${userName}`)
        .then((res) => {
          let fetchedUser = res.data;

          if(fetchedUser.disabledAt > 0 || fetchedUser.deletedAt > 0) {
            toast.error('Profile is currently not available')
            navigate(-1);
          } else {
            setUser(fetchedUser);
            setError(false);
          }
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => setLoading(false));
    } else {
      setUser(userRecord);
      setLoading(false);
    }
  }, [userRecord, isOtherUser, userName]);

  useEffect(() => {
    const uploadFile = async () => {
      try {
        if (file) {
          toast.info("Uploading Profile Pic");
          const formData = new FormData();
          formData.append("id", userRecord.id);
          formData.append("mediaId", userRecord.media?.id || "");
          formData.append("file", file);
          await axios.post(PROFILE_UPDATE, formData).then((res) => {
            console.log(res);
            toast.success("Profile Pic updated Successfully");
            refreshUserRecord(dispatch);
          });
        }
      } catch (error) {}
    };
    uploadFile();
  }, [file]);

  if (loading)
    return <CircularProgress />;

  if (error)
    return <>Error</>;

  return (
    <Layout>
      <div className={clsx("w-full flex flex-col", classes.mainContainer)}>
        <div className="relative">
          <Title back={ROOT}>
            <span>Profile</span>
            {isOtherUser && (
              <span className="absolute right-0">
                <IconButton
                  onClick={() => {
                    setOpenUserOptions(true);
                  }}
                >
                  <MoreVert
                    sx={{ alignSelf: "center" }}
                    fontSize="medium"
                    className="cursor-pointer"
                  />
                </IconButton>
              </span>
            )}
          </Title>
        </div>
        <div className="flex flex-row px-5 py-3">
          {!isOtherUser && (
            <div>
              <form
                className="relative"
                method="post"
                encType="multipart/form-data"
                onClick={() => fileInput.current.click()}
              >
                <Avatar
                  className="h-20 w-20 object-contain"
                  src={user?.media?.avatarUrl}
                />
                <img
                  className="absolute bottom-0 right-0"
                  src={camera}
                  alt="Camera"
                />
                <input
                  onChange={onChange}
                  ref={fileInput}
                  type="file"
                  className="hidden"
                  accept="image/*"
                />
              </form>
            </div>
          )}
          {isOtherUser && (
            <Avatar
              className="h-20 w-20 object-contain"
              src={user?.media?.avatarUrl}
            />
          )}
          <div className="p-2 pl-4">
            <Typography
              variant="h6"
              className={clsx(classes.textGrey, "font-bold")}
            >
              {user?.userName}
            </Typography>
            <Typography className={clsx(classes.textGrey, "leading-none")}>
              <small>
                {user?.firstName} {user?.lastName}
              </small>
            </Typography>
            {!isOtherUser && (
              <Typography className={clsx(classes.textGrey, "leading-none")}>
                <small>{user?.emailAddress}</small>
              </Typography>
            )}
            {!isOtherUser && (
              <div>
                <Link to={EDIT_PROFILE}>
                  <button
                    className={clsx(
                      classes.textColor,
                      "cursor-pointer text-sm font-bold underline"
                    )}
                  >
                    Edit Profile
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="px-5">
          <Typography className={clsx(classes.textGrey, "font-semibold")}>
            About
          </Typography>
          <Typography
            className={clsx(
              classes.textGrey,
              "text-sm",
              !user?.about && "italic"
            )}
          >
            {user?.about || "No description"}
          </Typography>
        </div>

        {qrCodeDataUri && (
          <div className="flex items-center justify-center mb-4">
            <div
              className="flex flex-col items-center"
            >
              <QrModalImage src={qrCodeDataUri} />
              <span className="text-xs text-center">Click to expand</span>
            </div>
            {/* {Boolean(navigator?.share) && (
              <IconButton onClick={handleQrCodeShare}>
                <img src={share} />
              </IconButton>
            )} */}
          </div>
        )}
        {(isOtherUser && user) && (
          <OtherUserMutualPosts user={user} />
        )}
        {!isOtherUser && (
          <div className="overflow-y-auto scrollbar-hidden">
            <TabPane tabs={tabs} activeTab={activeTab} onTabChange={onTabChange}>
              <MyCommunities />
              <div>
                <MyGroups />
              </div>
              <div>
                <MyConnections />
              </div>
            </TabPane>
          </div>
        )}
      </div>

      <GroupModal
        open={openUserOptions}
        setOpen={setOpenUserOptions}
        options={userOptions}
      />
    </Layout>
  );
}

export default Profile;
